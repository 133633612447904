import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

class ToTop extends React.Component {
  constructor(props) {
    super(props)

    this.handlClick = this.handleClick.bind(this)
    typeof window !== 'undefined' && smoothscroll.polyfill()
  }

  handleClick() {
    typeof window !== 'undefined' &&
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }

  render() {
    return (
      <div className="fixed bottom-0 right-0 mb-2 xsm:mb-4 mr-2 xsm:mr-4 md:mb-10 md:mr-10">
        <button
          onClick={this.handlClick}
          className="w-12 h-12 bg-white border-2 border-indigo-darkest"
          aria-label="Scroll to top"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="w-full h-full stroke-current"
          >
            <polyline points="18 15 12 9 6 15" />
          </svg>
        </button>
      </div>
    )
  }
}

export default ToTop
