import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/modinfinity-light.svg'

export default function Footer({ wpOptions }) {
  const { phone } = wpOptions
  const { email } = wpOptions
  const phoneURL = 'tel:' + phone
  const emailURL = 'mailto:' + email
  return (
    <div className="w-full p-6 xxl:py-16 flex flex-col items-center bg-indigo-base">
      <Link
        to="/"
        title="Modinfinity home page"
        className="w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/5 xxl:w-1/6"
      >
        <img src={logo} alt="Modinfinity logo" />
      </Link>
      <div className="mt-4 flex flex-col items-center">
        <a
          href={emailURL}
          className="no-underline hover:underline text-indigo-lightest font-bold lg:font-semibold text-lg"
        >
          {email}
        </a>
        <a
          href={phoneURL}
          className="mt-3 no-underline hover:underline text-indigo-lightest font-bold lg:font-semibold text-lg"
        >
          {phone}
        </a>
      </div>
      <div className="w-1/2 sm:w-2/5 md:w-1/3 lg:w-1/5 xxl:w-1/6 mt-6 sm:mt-8 xl:px-12 flex justify-between">
        <a
          className="group"
          href={wpOptions.facebook}
          title="Modinfinity Facebook page"
        >
          <div className="w-8 h-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-indigo-lightest group-hover:stroke-indigo fill-transparent group-hover:fill-indigo-lightest"
            >
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
            </svg>
          </div>
        </a>
        <a
          className="group"
          href={wpOptions.instagram}
          title="Modinfinity Instagram profile"
        >
          <div className="w-8 h-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-indigo-lightest group-hover:stroke-indigo fill-transparent group-hover:fill-indigo-lightest"
            >
              <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
              <line x1="17.5" y1="6.5" x2="17.5" y2="6.5" />
            </svg>
          </div>
        </a>
        <a
          className="group"
          href={wpOptions.twitter}
          title="Modinfinity Twitter profile"
        >
          <div className="w-8 h-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-indigo-lightest group-hover:stroke-indigo fill-transparent group-hover:fill-indigo-lightest"
            >
              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
            </svg>
          </div>
        </a>
      </div>
    </div>
  )
}
