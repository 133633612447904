import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import ToTop from './to-top'
import '../styles/main.css'

import 'typeface-lato'

const Layout = ({ children, data, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wordpressAcfOptions {
          options {
            facebook
            instagram
            twitter
            phone
            email
          }
        }
      }
    `}
    render={data => (
      <div className="min-h-screen font-sans bg-white relative">
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header
          location={location ? location : ''}
          siteTitle={data.site.siteMetadata.title}
        />
        <div>{children}</div>
        <Footer wpOptions={data.wordpressAcfOptions.options} />
        <ToTop />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
