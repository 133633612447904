import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/modinfinity.svg'
import logo_colored from '../images/modinfinity-indigo.svg'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { navToggled: false }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(state => ({
      navToggled: !state.navToggled,
    }))
  }

  render() {
    return (
      <div
        className={
          (this.state.navToggled ? 'fixed min-h-screen' : 'relative h-auto') +
          ' w-full md:h-auto z-50 bg-white'
        }
      >
        <nav className="w-full relative">
          <div className="p-4 md:p-8 md:flex md:items-center md:justify-between">
            <div className="w-full flex items-center justify-between">
              <div>
                <Link className="no-underline" to="/">
                  <img
                    className="w-40 xsm:w-48 h-auto"
                    src={this.props.location === '/' ? logo_colored : logo}
                    alt="Modinfinity logo"
                  />
                </Link>
              </div>
              <div className="md:hidden">
                <button
                  id="menu-open"
                  onClick={this.handleClick}
                  className={
                    (this.state.navToggled ? 'hidden ' : 'block ') + 'w-8 h-8'
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    className="w-full h-full stroke-current"
                  >
                    <line x1="3" y1="12" x2="21" y2="12" />
                    <line x1="3" y1="6" x2="21" y2="6" />
                    <line x1="3" y1="18" x2="21" y2="18" />
                  </svg>
                </button>
                <button
                  id="menu-close"
                  onClick={this.handleClick}
                  className={
                    (this.state.navToggled ? 'block ' : 'hidden ') + 'w-8 h-8'
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    className="w-full h-full stroke-current"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              id="nav-links"
              className={
                (this.state.navToggled ? 'flex pt-12' : 'hidden') +
                ' md:pt-0 md:block flex-col h-full md:h-auto'
              }
            >
              <Link
                className={
                  (this.props.location === '/services'
                    ? 'border-indigo-base bg-indigo-lightest md:bg-transparent'
                    : 'border-transparent') +
                  ' py-3 md:pt-0 md:pb-3 border-l-4 md:border-l-0 md:border-b-4 hover:border-indigo-base no-underline text-center text-indigo-darkest text-xl font-extrabold'
                }
                to="/services"
              >
                Services
              </Link>
              <Link
                className={
                  (this.props.location === '/work'
                    ? 'border-indigo-base bg-indigo-lightest md:bg-transparent'
                    : 'border-transparent') +
                  ' md:mx-8 py-3 md:pb-3 md:pt-0 border-l-4 md:border-l-0 md:border-b-4 hover:border-indigo-base no-underline text-center text-indigo-darkest text-xl font-extrabold'
                }
                to="/work"
              >
                Work
              </Link>
              <Link
                className={
                  (this.props.location === '/about'
                    ? 'border-indigo-base bg-indigo-lightest md:bg-transparent'
                    : 'border-transparent') +
                  ' md:mr-8 py-3 md:pb-3 md:pt-0 border-l-4 md:border-l-0 md:border-b-4 hover:border-indigo-base no-underline text-center text-indigo-darkest text-xl font-extrabold'
                }
                to="/about"
              >
                About
              </Link>
              <Link
                className={
                  (this.props.location === '/contact'
                    ? 'border-indigo-base bg-indigo-lightest md:bg-transparent'
                    : 'border-transparent') +
                  ' py-3 md:pb-3 md:pt-0 border-l-4 md:border-l-0 md:border-b-4 hover:border-indigo-base no-underline text-center text-indigo-darkest text-xl font-extrabold'
                }
                to="/contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
